*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* css */
html {
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee;
  
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9bc1de;
    border-radius:10px
  
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #83abc9;
  }